<template>
  <el-cascader
    v-model="optionValue"
    :options="options"
    @change="handleChange"
    clearable
    :style="styleRef"
    :props="rolesProps"
  >
  </el-cascader>
</template>
<script>
import { getCascadeRegion } from "@/api/common";

export default {
  props: {
    isRequired: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array
    },
    widthStyle: {
      type: String,
      default: "100%"
    },
    styleRef: {
      type: Object
    },
    type: {
      type: String
    }
  },
  created() {
    this.getCascadeList(0);
  },
  components: {},
  filters: {},
  data() {
    return {
      optionValue: this.value,
      options: [],
      rolesProps: {
        multiple: this.isMultiple, // 是否多选
        lazy: true,
        lazyLoad: this.cascaderLazyLoad,
        checkStrictly: true
      }
    };
  },
  methods: {
    /**
     * 查询级联标签
     */
    getCascadeList(parentId) {
      const params = {
        parentId
      };
      getCascadeRegion(params).then(resp => {
        this.options = this.packagingData(resp.data) || [];
      });
    },
    /**
     * 选中角色获取的值
     * @param node
     * @param resolve
     */
    cascaderLazyLoad(node, resolve) {
      if (!node.root) {
        const params = {
          parentId: node.value || 0
        };
        getCascadeRegion(params).then(resp => {
          const data = this.packagingData(resp.data);
          resolve(data);
        });
      }
    },
    /**
     * 节点选中的值
     * @param events
     */
    handleChange(events) {
      this.$emit("valueFunc", this.type, events);
    },
    packagingData(dataList) {
      const arr = [];
      if (!dataList || dataList.length === 0) {
        return arr;
      }
      dataList.map(item => {
        arr.push({
          value: item.id,
          label: item.name,
          parentId: item.parent_id
        });
      });
      return arr;
    }
  }
};
</script>

<style scoped lang="scss"></style>
