// ================  公共 start ======================================================================

/**
 * 根据key获取value
 * @param optionArr
 * @param key
 */
export function getValue(optionArr, key) {
  let resultVal = "";
  if (!optionArr || optionArr.length === 0) {
    return resultVal;
  }
  optionArr.map(item => {
    if (item.key === key) {
      resultVal = item.value;
    }
  });
  return resultVal;
}

/**
 * 获取短信的类型 register-注册，login-登录，changepassword-修改密码
 * @type {{}}
 */
export const SMS_TYPE = {
  REGISTER: "register",
  LOGIN: "login",
  CHANGEPASSWORD: "changepassword"
};

/**
 * 上传图片的类型 common-公共,device-设备,ad-广告
 * @type {{}}
 */
export const UPLOAD_IMG_TYPE = {
  COMMON: "common",
  DEVICE: "device",
  AD: "ad"
};

/**
 * 弹框的标题
 * @type {{CREATE: string, UPDATE: string}}
 */
export const DIALOG_TITLE_TYPE = {
  UPDATE: "编辑",
  CREATE: "添加"
};

/**
 * 附件类型 0-没有，1-视频，2-文件，
 * @type {[{value: string, key: number},{value: string, key: number}]}
 */
export const ATT_TYPE = [
  {
    key: 0,
    value: "没有"
  },
  {
    key: 1,
    value: "视频"
  },
  {
    key: 2,
    value: "文件"
  }
];

// ================  公共 end ======================================================================
/**
 * 用户的状态
 * @type {[{value: string, key: number},{value: string, key: number}]}
 */
export const IS_FORBIDDEN = [
  {
    key: 0,
    value: "正常"
  },
  {
    key: 1,
    value: "冻结"
  }
];
/**
 * 菜单类型
 * @type {[{value: string, key: string},{value: string, key: string}]}
 */
export const MENU_TYPE = [
  {
    key: "MENU",
    value: "菜单"
  },
  {
    key: "ACTION",
    value: "操作"
  }
];

/**
 * 按摩椅的类别 0-按摩舱，1-监控，2-广告屏
 * @type {[{value: string, key: number}]}
 */
export const CATEGORY_TYPE = [
  {
    key: 0,
    value: "按摩舱"
  },
  {
    key: 1,
    value: "监控"
  },
  {
    key: 2,
    value: "广告屏"
  },
  {
    key: 3,
    value: "爱眼仪"
  }
];
/**
 * 门店状态 1-装配中，2-营业中，3-休业中，4-已关闭
 * @type {[{value: string, key: number}]}
 */
export const STORE_STATUS = [
  {
    key: 1,
    value: "装配中"
  },
  {
    key: 2,
    value: "营业中"
  },
  {
    key: 3,
    value: "休业中"
  },
  {
    key: 4,
    value: "已关闭"
  }
];

/**
 * 设备的状态 1-装配中，2-空闲中，3-预约中, 4-作业中，5-待维护，6-维修中
 * @type {[{}]}
 */
export const INTLLIGENT_STATUS = [
  {
    key: 1,
    value: "装配中"
  },
  {
    key: 2,
    value: "空闲中"
  },
  {
    key: 3,
    value: "预约中"
  },
  {
    key: 4,
    value: "作业中"
  },
  {
    key: 5,
    value: "待维护"
  },
  {
    key: 6,
    value: "维修中"
  }
];
/**
 * 控制平台 0-系统，1-涂鸦
 * @type {[{value: string, key: number},{value: string, key: number},{value: string, key: number},{value: string, key: number},{value: string, key: number},null]}
 */
export const INTLLIGENT_CONTROL_PLATFORM = [
  {
    key: 0,
    value: "系统"
  },
  {
    key: 1,
    value: "涂鸦"
  }
];

/**
 * 定价的状态
 * @type {[{value: string, key: number},{value: string, key: number}]}
 */
export const PRICING_STATUS = [
  {
    key: 0,
    value: "有效"
  },
  {
    key: 1,
    value: "作废"
  }
];
/**
 * 定价价格状态 type:0-体验，1-按使用时长扣费,2-购买
 * @type {[{value: string, key: number}]}
 */
export const PRICING_PRIC_TYPE = [
  {
    key: 0,
    value: "体验套餐"
  },
  {
    key: 1,
    value: "收费套餐"
  }
];
/**
 * 计时方式 timing:1-服务累计时长，2-按服务最长时长；
 * @type {[{}]}
 */
export const TIMING_TYPE = [
  {
    key: 1,
    value: "服务累计时长"
  },
  {
    key: 2,
    value: "按服务最长时长"
  }
];

/**
 * 门店代理
 * @type {[{value: string, key: number},{value: string, key: number}]}
 */
export const STORE_TYPE = [
  {
    key: 1,
    value: "直营"
  },
  {
    key: 2,
    value: "代理"
  }
];
/**
 * 服务状态:1-装配中，2-空闲中，3-作业中，4-待维护，5-维修中，6-已卸载
 * @type {[{}]}
 */
export const SERVER_STATUS = [
  {
    key: 1,
    value: "装配中"
  },
  {
    key: 2,
    value: "空闲中"
  },
  {
    key: 3,
    value: "作业中"
  },
  {
    key: 4,
    value: "待维护"
  },
  {
    key: 5,
    value: "维修中"
  },
  {
    key: 6,
    value: "已卸载"
  }
];
/**
 * 支付状态 1-待支付，2-支付中，3-已支付，4-已作废
 * @type {[{value: string, key: number}]}
 */
export const PAYMENT_STATUS = [
  {
    key: 1,
    value: "待支付"
  },
  {
    key: 2,
    value: "支付中"
  },
  {
    key: 3,
    value: "已支付"
  },
  {
    key: 4,
    value: "已作废"
  }
];

/**
 * 退款状态:0-待提交，1-退款中，2-退款成功，3-退款失败
 * @type {[{value: string, key: number}]}
 */
export const REFUND_STATUS = [
  {
    key: 0,
    value: "待提交"
  },
  {
    key: 1,
    value: "退款中"
  },
  {
    key: 2,
    value: "退款成功"
  },
  {
    key: 3,
    value: "退款失败"
  }
];

/**
 * 支付平台：1-微信，2-支付宝，3-澳门积分，4-系统积分
 * @type {[{value: string, key: number}]}
 */
export const PLATFORM = [
  {
    key: 1,
    value: "微信"
  },
  {
    key: 2,
    value: "支付宝"
  },
  {
    key: 3,
    value: "澳门积分"
  },
  {
    key: 4,
    value: "系统积分"
  }
];
/**
 * 用户状态 状态:1-正常，2-冻结
 * @type {[{value: string, key: number}]}
 */
export const USER_ACCOUNT_STATUS = [
  {
    key: 1,
    value: "正常"
  },
  {
    key: 2,
    value: "冻结"
  }
];

/**
 * VIP等级状态 状态:1-正常，2-下线
 * @type {[{value: string, key: number}]}
 */
export const VIP_GRADE_STATUS = [
  {
    key: 1,
    value: "正常"
  },
  {
    key: 2,
    value: "下线"
  }
];

/**
 * 服务单详情状态:1-待支付，2-支付处理中，3-已支付，4-服务中，5-已结束，6-已作废
 * @type {[{value: string, key: number}]}
 */
export const SERVICE_BILL_STATUS = [
  {
    key: 1,
    value: "待支付"
  },
  {
    key: 2,
    value: "支付处理中"
  },
  {
    key: 3,
    value: "已支付"
  },
  {
    key: 4,
    value: "服务中"
  },
  {
    key: 5,
    value: "已结束"
  },
  {
    key: 6,
    value: "已作废"
  }
];
/**
 * 评价类型 1-非常不满意，2-不满意，3-满意，4-很满意，5-非常满意
 * @type {[{value: string, key: number}]}
 */
export const EVALUATE_TYPE = [
  {
    key: 1,
    value: "非常不满意"
  },
  {
    key: 2,
    value: "不满意"
  },
  {
    key: 3,
    value: "满意"
  },
  {
    key: 4,
    value: "很满意"
  },
  {
    key: 5,
    value: "非常满意"
  }
];

/**
 * 广告页的类型 页面:1-首页，2-下单页面，3-控制页面
 * @type {[{value: string, key: number}]}
 */
export const AD_TYPE = [
  {
    key: 1,
    value: "首页"
  },
  {
    key: 2,
    value: "下单页面"
  },
  {
    key: 3,
    value: "控制页面"
  }
];

/**
 * 广告页的媒体类型 页面:1-图片，2-视频
 * @type {[{value: string, key: number}]}
 */
export const AD_MEDIA_TYPE = [
  {
    key: 1,
    value: "图片"
  },
  {
    key: 2,
    value: "视频"
  }
];

/**
 * 广告页的状态 1-显示，2-隐藏
 * @type {[{value: string, key: number}]}
 */
export const AD_STATUS = [
  {
    key: 1,
    value: "显示"
  },
  {
    key: 2,
    value: "隐藏"
  }
];
