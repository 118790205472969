<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入关键字"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in STORE_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">门店数量：{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="门店名称"
        width="120"
        align="center"
        prop="name"
      ></el-table-column>
      <el-table-column label="门店类型" width="100" align="center" prop="type">
        <template slot-scope="scope">
          {{ getValue(STORE_TYPE, scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column
        label="门店状态"
        width="100"
        align="center"
        prop="status"
      >
        <template slot-scope="scope">
          {{ getValue(STORE_STATUS, scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column
        label="省/市/区/街道"
        width="220"
        align="center"
        prop="name"
      >
        <template slot-scope="scope">
          {{ scope.row.province ? scope.row.province.name : "无" }} /
          {{ scope.row.city ? scope.row.city.name : "无" }} /
          {{ scope.row.area ? scope.row.area.name : "无" }} /
          {{ scope.row.street ? scope.row.street.name : "无" }}
        </template>
      </el-table-column>
      <el-table-column
        label="详细地址"
        width="220"
        align="center"
        prop="address"
      ></el-table-column>
      <el-table-column
        label="联系电话"
        width="120"
        align="center"
        prop="phone"
      ></el-table-column>
      <el-table-column
        label="经度"
        width="120"
        align="center"
        prop="longitude"
      ></el-table-column>
      <el-table-column
        label="纬度"
        width="120"
        align="center"
        prop="latitude"
      ></el-table-column>
      <el-table-column label="营业时间" width="120px" align="center">
        <template slot-scope="scope">
          {{ scope.row.open_time }} -
          {{ scope.row.close_time }}
        </template>
      </el-table-column>
      <el-table-column
        prop="showImgs"
        label="图片"
        width="120px"
        align="center"
      >
        <template slot-scope="scope">
          <el-image
            :src="scope.row.showImgs.show_url"
            style="width: 100px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column prop="introduction" label="简介" align="center">
      </el-table-column>
      <el-table-column
        prop="nickname"
        label="操作人"
        width="120px"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.opt_user.nickname }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="160px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="160px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="180"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择类型"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in STORE_TYPE"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select
            v-model="form.status"
            placeholder="请选择状态"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in STORE_STATUS"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="省市区街道" prop="cascadeRegion">
          <CascadeRegion
            :isRequired="true"
            :value="form.cascadeRegion"
            @valueFunc="getQueryValueFunc"
            type="cascadeRegion"
            :styleRef="{ width: '100%' }"
          />
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="form.address" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="经度" prop="longitude">
          <el-button @click="oppenOrgAddr">选择经纬度</el-button>
          <!-- 地图选点 -->
          <mapSelect @chooseOrgAddr="chooseOrgAddr" ref="ref"></mapSelect>
          <p>经度: {{ form.longitude }}</p>
          <p>纬度: {{ form.latitude }}</p>
        </el-form-item>
        <el-form-item label="图片" prop="imgs">
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.COMMON"
            @valueFunc="getImgsValueFunc"
            paramsType="imgs"
            :model="form.imgs"
            :maxCount="1"
          />
        </el-form-item>
        <el-form-item label="营业时间" prop="openTime">
          <el-time-picker
            is-range
            v-model="form.businessHours"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="简介" prop="introduction">
          <el-input
            type="textarea"
            v-model="form.introduction"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  DIALOG_TITLE_TYPE,
  UPLOAD_IMG_TYPE,
  getValue,
  STORE_STATUS,
  STORE_TYPE
} from "@/utils/enumUtile";
import { formatApiDate, getFileName } from "@/utils/utils";
import { getStoreList, createStore } from "@/api/store";
import UploadList from "@/views/components/uploadList.vue";
import CascadeRegion from "@/views/components/cascadeRegion.vue";
import moment from "moment";
import mapSelect from "@/views/components/map/mapSelect.vue";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    },
    UPLOAD_IMG_TYPE() {
      return UPLOAD_IMG_TYPE;
    },
    getValue() {
      return getValue;
    },
    STORE_STATUS() {
      return STORE_STATUS;
    },
    STORE_TYPE() {
      return STORE_TYPE;
    }
  },
  components: { UploadList, CascadeRegion, mapSelect },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        category: ""
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogStatus: "",
      rules: {
        status: [
          { required: true, message: "状态不能为空", trigger: "change" }
        ],
        name: [{ required: true, message: "名称不能为空", trigger: "change" }],
        imgs: [{ required: true, message: "图片不能为空", trigger: "change" }],
        provinceId: [
          { required: true, message: "省份不能为空", trigger: "change" }
        ],
        cityId: [{ required: true, message: "市不能为空", trigger: "change" }],
        address: [
          { required: true, message: "详细地址不能为空", trigger: "change" }
        ],
        longitude: [
          { required: true, message: "经度不能为空", trigger: "change" }
        ],
        latitude: [
          { required: true, message: "纬度不能为空", trigger: "change" }
        ],
        cascadeRegion: [
          { required: true, message: "省市区街道不能为空", trigger: "change" }
        ]
      },
      form: {
        id: "",
        name: "",
        status: "",
        cascadeRegion: [], // 省市区街道
        provinceId: "",
        cityId: "",
        areaId: "",
        streetId: "",
        address: "",
        phone: "",
        longitude: "", //经度
        latitude: "", // 纬度
        imgs: [],
        introduction: "",
        businessHours: [], // 营业时间
        openTime: "",
        closeTime: "",
        type: "" // 类型：1-直营，2-代理
      },
      btnLoading: false,
      editObj: {} // 修改的对象
    };
  },
  mounted() {},
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },

    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getStoreList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;
      this.dialogStatus =
        editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.status = editObj.status;
        this.form.name = editObj.name;
        this.form.provinceId = editObj.province_id;
        this.form.cityId = editObj.city_id;
        this.form.areaId = editObj.area_id;
        this.form.streetId = editObj.street_id;
        this.form.address = editObj.address;
        this.form.phone = editObj.phone;
        this.form.longitude = editObj.longitude;
        this.form.latitude = editObj.latitude;
        this.form.introduction = editObj.introduction;
        this.form.cascadeRegion = [
          editObj.province_id,
          editObj.city_id,
          editObj.area_id,
          editObj.street_id
        ];
        this.form.openTime = editObj.open_time;
        this.form.closeTime = editObj.close_time;
        const now = moment();
        const openTime = moment(editObj.open_time, "HH:mm:ss");
        const closeTime = moment(editObj.close_time, "HH:mm:ss");
        this.form.businessHours = [
          new Date(
            now.year(),
            now.month(),
            now.date(),
            openTime.hours(),
            openTime.minutes()
          ),
          new Date(
            now.year(),
            now.month(),
            now.date(),
            closeTime.hours(),
            closeTime.minutes()
          )
        ];
        this.form.type = editObj.type;

        if (editObj.showImgs) {
          const attDataArr = [];
          const imgs = editObj.showImgs;
          attDataArr.push({
            dbUrl: imgs.url,
            url: imgs.show_url,
            name: getFileName(imgs.url)
          });
          this.form.imgs = attDataArr;
        }
      } else {
        this.form = {
          id: "",
          name: "",
          status: "",
          cascadeRegion: [],
          provinceId: "",
          cityId: "",
          areaId: "",
          streetId: "",
          address: "",
          phone: "",
          longitude: "",
          latitude: "",
          imgs: [],
          introduction: "",
          businessHours: [],
          openTime: "",
          closeTime: "",
          type: ""
        };
        this.$refs.form.resetFields();
      }
    },

    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            ...form
          };
          if (form.imgs && form.imgs.length !== 0) {
            const urlArr = form.imgs.map(obj => obj["dbUrl"]);
            params.imgs = urlArr.join(",");
          }

          if (form.businessHours && form.businessHours.length !== 0) {
            params.openTime = moment(form.businessHours[0]).format("HH:mm");
            params.closeTime = moment(form.businessHours[1]).format("HH:mm");
          }

          delete params.businessHours;
          delete params.cascadeRegion;

          this.btnLoading = true;
          createStore(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    /**
     *  获取图片的值
     * @param type
     * @param value
     */
    getImgsValueFunc(type, value) {
      console.log("type, value", type, value);
      if (type === "imgs") {
        this.form.imgs = value;
      }
    },
    /**
     * 省市区街道的值获取
     * @param type
     * @param resultArr
     */
    getQueryValueFunc(type, resultArr) {
      if (type === "cascadeRegion") {
        this.form.cascadeRegion = resultArr;
        if (resultArr && resultArr.length !== 0) {
          this.form.provinceId = resultArr[0];
          this.form.cityId = resultArr[1];
          this.form.areaId = resultArr[2];
          this.form.streetId = resultArr[3];
        } else {
          this.form.provinceId = "";
          this.form.cityId = "";
          this.form.areaId = "";
          this.form.streetId = "";
        }
      }
    },
    /**
     * 打开经纬度
     */
    oppenOrgAddr() {
      this.$refs.ref.openDialog();
    },
    // 选取地理位置后的回调
    chooseOrgAddr(data) {
      this.form.latitude = data.lat;
      this.form.longitude = data.lng;
      this.$refs.ref.closeDialog();
    }
  }
};
</script>
<style scoped lang="scss"></style>
