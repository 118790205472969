<template>
  <div>
    <el-upload
      :action="uploadImgApi"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      class="upload_list_warp"
      :on-success="handleSuccess"
      :data="{ type: typeProps }"
      v-model="uploadImgList"
      :file-list="uploadImgList"
      :limit="maxCount"
      :on-exceed="handleExceed"
      :before-upload="beforeAvatarUpload"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <div slot="tip" class="el-upload__tip">
      只能上传 jpg,jpeg,png 格式视频，且不超过50MB
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { uploadImgApi } from "@/api/common";
import { UPLOAD_IMG_TYPE } from "@/utils/enumUtile";
import { getFileName } from "@/utils/utils";
import { Message } from "element-ui";

export default {
  computed: {
    uploadImgApi() {
      return uploadImgApi;
    }
  },
  props: {
    typeProps: UPLOAD_IMG_TYPE,
    paramsType: String,
    model: Array,
    maxCount: {
      type: Number
    } // 最大个数
  },
  watch: {
    model(value) {
      this.setImgList(value);
    }
  },
  data() {
    return {
      uploadImgList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false
    };
  },
  created() {
    if (this.model) {
      this.setImgList(this.model);
    }
  },
  methods: {
    /**
     * 设置图片
     * @param img
     */
    setImgList(img) {
      this.uploadImgList = img;
    },
    /**
     * 删除
     * @param file
     * @param fileList
     */
    handleRemove(file, fileList) {
      const listArr = this.uploadImgList;

      const uid = file.uid;
      const resultListArr = listArr.filter(item => item.uid !== uid);

      this.uploadImgList = resultListArr;
      this.$emit("valueFunc", this.paramsType, resultListArr);
    },
    /**
     * 放大查看
     * @param file
     */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    /**
     * 确认上传
     * @param res
     */
    handleSuccess(res) {
      console.log("resresresresresres", res);
      if (res.code === -1) {
        Message({
          message: res.msg,
          type: "error",
          duration: 3 * 1000
        });
        return;
      }
      const listArr = this.uploadImgList;
      const obj = {
        name: getFileName(res.data.url),
        dbUrl: res.data.url,
        url: res.data.show_url
      };
      listArr.push(obj);

      console.log("listArr", listArr);
      this.uploadImgList = listArr;

      this.$emit("valueFunc", this.paramsType, listArr);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.maxCount} 个文件`);
    },
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/jpeg", "image/png"].includes(
        file.type
      );
      const isLt2M = file.size / 1024 / 1024 < 50;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 jpeg，JPG，png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 50MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>
<style lang="scss">
.el-upload__tip {
  margin-top: 0px;
  line-height: 30px;
  color: #fcad2c;
  margin-bottom: 10px;
}
</style>
